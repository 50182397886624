.user_container {
    height: ~'calc(100vh - 155px)';
    display: flex;
    justify-content: center;
    padding: 60px 100px;

    &_left {
        margin-right: 80px;
        width: 200px;
        height: 300px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        border: 1px solid rgb(222, 226, 230);
        padding: 20px;
    }

    &_avatar {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }
}

.table_container {
    margin-top: 50px;
    max-height: 500px;
    overflow-y: auto;
}

.table_td {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.btn_container {
    width: 100%;
    padding: 20px 0 0 45px;
    position: absolute;
    bottom: 20px;
}

.activity_container {
    margin-left: 40px;
}

.activity_panel {
    display: inline-flex !important;
}

.user_card {
    width: 255px;
    height: 350px;
}

.activity_item {
    margin: 0 20px 20px 0;
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
