.ground {
    background: linear-gradient(
        90deg,
        rgba(95, 28, 132, 1) 0%,
        rgba(34, 31, 102, 1) 100%
    );
    margin-bottom: 20;
    padding-bottom: 30px;
}

.main-video {
    height: 90vh;
}

.buttonsTray {
    background: #d298db;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    text-align: center;
    @media (min-width: 800px) {
        max-width: 720px;
    }
    height: 33px;
}

.buttons {
    background: white;
    color: #a324f2;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
    font-weight: bold;
    border-radius: 20px;
    margin-left: 5;
    margin-right: 5;
}

.logos {
    float: none;
    display: block;
    margin-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
}

.frame {
    display: block;
    height: 100%;
    border: 2px solid #efdb94;
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 1rem;
    text-align: center;
    position: relative;
    .tag {
        background: #efdb94;
        color: black;
        backdrop-filter: blur(4px);
        border-radius: 20px;
        margin: -12px auto 10px;
        height: 23px;
    }
    &.VIP {
        border-color: #fc6100;
        .tag {
            background: linear-gradient(
                    269.98deg,
                    #eecb12 27.19%,
                    rgba(255, 255, 255, 0) 56.18%
                ),
                #fa7b05;
        }
    }
    p {
        text-align: left;
        font-size: small;
    }
    .logoTag {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        width: 140px;
        height: 42px;
        object-fit: contain;
    }
}
.card {
    background-color: #745491;
    white-space: pre-line;
}
